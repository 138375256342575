@font-face {
  font-family: "Prompt";
  src: local("Prompt"),
    url("../assets/fonts/Prompt-Regular.ttf") format("truetype");
  font-weight: normal;
}
* {
  text-rendering: optimizeLegibility;
  // box-shadow: none !important;
}
#root {
}
body {
  margin: 0;
  padding: 0;
  font-family: "Prompt", "Roboto" !important;
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
.analyticsIcon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  object-fit: contain;
  margin-bottom: 10px;
}
.icon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.smIcon {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.lgIcon {
  width: 140px;
  height: 140px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.block {
  margin-top: 60px;
}
.header {
  text-align: center;
  font-size: 48px;
  padding-top: 15px;
  font-weight: 700;
}
.card {
  background-color: #1f0c68;
  color: white;
  text-align: center;
  overflow: hidden;
  border-radius: 30px;
  margin: 10px;
  padding: 30px;
  box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.5);
}
a .card:hover {
  background-color: rgb(20, 20, 20);
}

.alertCard {
  background-color: #5b7bab;
  color: #000000;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
}
.cardHeader {
  color: white;
  font-size: 28px;
  padding-bottom: 15px;
  font-weight: 700;
}
.cardSubHeader {
  color: #bdb8b8;
  font-size: 15px;
  padding-bottom: 15px;
}
.cardLgValue {
  color: white;
  font-size: 32px;
  padding-bottom: 10px;
  font-weight: 500;
}
.tab {
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}
.lightTab {
  background-color: rgba(225, 225, 225, 0.3);
  overflow: hidden;
  border-radius: 10px;
  margin: "0 10px";
  vertical-align: bottom;
  padding: 20px 20px;
}
.vaultHeader {
  text-align: left;
  font-size: 24px;
  vertical-align: middle;
  font-weight: 800;
  margin: 25px auto;
  max-width: 800px;
}
.vaultSubHeader {
  text-align: left;
  line-height: 1.2;
  font-size: 22px;
  font-weight: 500;
  margin: 25px auto;
  max-width: 800px;
}
.coloredTitle {
  color: #e84242;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}
.coloredText {
  color: #e84242;
}
.dashboardHeader {
  font-size: 30px;
  vertical-align: middle;
  font-weight: 700;
}
.listHeader {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.listContent {
  text-align: right;
  font-size: 22px;
  font-weight: 600;
}
.valueHeader {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 15px;
}
.value {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: white;
}
.price {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.rightValue {
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: right;
}

.tooltip {
  padding: 0;
  margin-left: 10px;
  margin-top: -2px;
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.labelLink {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.cardTitleItem {
  width: 20%;
}
.w20 {
  width: 20%;
}
.w15 {
  width: 15%;
}
.w10 {
  width: 10%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}

.rightText {
  float: right;
  font-weight: 600;
  margin-top: 20px;
}

.speedo-segment {
  margin: 10px;
  padding: 10px;
}

.InfiniteCarouselArrowIcon {
  border-color: black;
}
.MuiSelect-icon {
  color: white;
}
.web3modal-modal-lightbox {
  z-index: 2000 !important;
}

.betweenRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
